import React, { useState, useEffect, useRef } from 'react';

import { constructMinuteString, numberFormatting } from '../../../Util/Util';
import useAnalyticsEventTracker from '../../../GoogleAnalytics/useAnalyticsEventTracker';

import './LegalServiceDetail.scss';

import CheckoutDetail from '../CheckoutDetail/CheckoutDetail';
import { Toast } from 'react-bootstrap';
import ServiceHeader from '../../Utilities/ServiceHeader/ServiceHeader';
import ServiceOption from '../../Utilities/ServiceOption/ServiceOption';
import ServiceFooter from '../../Utilities/ServiceFooter/ServiceFooter';

import Init from '../../../Init/Init';

import { order } from '../../../Data/Transaction';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    return {
        customer: state.auth.data,
        isLogin: state.auth.isLogin
    };
}

const { constants, config } = Init;
const { whatsappIcon, puzzleIcon } = config;

const LegalServiceDetail = (props) => {
    const gaEventTracker = useAnalyticsEventTracker('Layanan Hukum');
    const { data } = props.serviceData;
    const service = data;

    /* Login Check */
    const [ isLogin, setIsLogin ] = useState(false);
    const [ customer, setCustomer ] = useState({});

    useEffect( () => {
        setIsLogin(props.isLogin);
    }, [ props.isLogin ]);

    useEffect( () => {
        setCustomer( props.customer );
    }, [ isLogin, props.customer ]);

    useEffect( () => {
        setInputs({
            whatsappNo: {
                label: 'No. Whatsapp',
                icon: whatsappIcon,
                ref: whatsappNo,
                value: customer.customer_phone_number,
                placeholder: 'Tuliskan No. WhatsApp mu',
                type: 'phone'
            },
            topic: {
                label: (service.hasOwnProperty('topicLabel') && service.topicLabel ? service.topicLabel : 'Inti Permasalahan'),
                icon: puzzleIcon,
                ref: topic,
                value: '',
                placeholder: `Tuliskan ${ service.hasOwnProperty('topicLabel') && service.topicLabel ? service.topicLabel : 'Inti Permasalahan' } mu!`,
                type: 'text'
            },
        });
    }, [ customer ]);

    /* Basic Vars */
    const [ buttonDisabled, setButtonDisabled ] = useState(true); 
    const [ options, setOptions ] = useState([1, 2, 4, 6, null]);
    const [ opt, setOpt ] = useState(0);
    const [ orderDetail, setOrderDetail ] = useState([
        {label: 'Jenis Layanan', value: service.name},
        {label: 'Total', value: `0 ${service.unit}`}
    ]);
    const [ priceDetail, setPriceDetail ] = useState([
        {label: 'Total', value: 0},
    ]); 
    const [toastContent, setToastContent] = useState({
        message: '',
        show: false
    });

    const whatsappNo = useRef('');
    const topic = useRef('');

    const [ inputs, setInputs ] = useState({
        whatsappNo: {
            label: 'No. Whatsapp',
            icon: whatsappIcon,
            ref: whatsappNo,
            value: '',
            placeholder: 'Tuliskan No. WhatsApp mu',
            type: 'phone'
        },
        topic: {
            label: (service.hasOwnProperty('topicLabel') && service.topicLabel ? service.topicLabel : 'Inti Permasalahan'),
            icon: puzzleIcon,
            ref: topic,
            value: '',
            placeholder: `Tuliskan ${ service.hasOwnProperty('topicLabel') && service.topicLabel ? service.topicLabel : 'Inti Permasalahan' } mu!`,
            type: 'text'
        },
    });

    const chooseQty = (k, v) => {
        let optList = options;
        let customVal = 0;

        if(service.code === 'KH') {
            customVal = v;
        } else {
            customVal = Math.floor(Math.min(v / service.portion, 1000)) * service.portion;
        }
        
        if(k === 4 && v > 0) {
            optList[4] = customVal;
            setOptions([...optList]);
        }

        setOpt(k);
    };

    const checkout = () => {
        const clientName = inputs.whatsappNo.value;
        const serviceCode = service.code;
        const serviceType = constants.serviceType.legalService;
        const quantity = options[opt];
        const transactionNotes = inputs.topic.value;

        ( async () => {
            const response = await order( clientName, serviceCode, serviceType, quantity, transactionNotes, constants.paymentService.purwantara );

            if( response.hasOwnProperty('errorCode') && response.errorCode === 0 ) {
                window.open( response.data.paymentLink, '_blank' );
                setButtonDisabled(true);
            } else {
                setToastContent({
                    message: response.data.map((item) => {
                        return item.message;
                    }).join('<br />'),
                    show: true
                });
            }
        } )();
    };

    const onModify = (id, ref) => {
        const inputCopy = inputs;
        inputCopy[id].ref = ref;
        inputCopy[id].value = ref.current.value;

        const whatsappValue = inputCopy.whatsappNo.value;
        const topicValue = inputCopy.topic.value;

        setInputs({
            whatsappNo: {...inputCopy.whatsappNo},
            topic: {...inputCopy.topic}
        });

        setButtonDisabled( !(whatsappValue !== '' && topicValue !== '') );
    };

    const closeToast = () => {
        setToastContent({
            ...toastContent,
            show: false
        });
    };

    const calcDetail = () => {
        const totalQty = service.unit !== 'menit' ? `${options[opt] * service.portion} ${service.unit}` : constructMinuteString( options[opt], service.portion );

        orderDetail[1].value = totalQty;
        setOrderDetail([...orderDetail]);

        priceDetail[0].value = options[opt] * service.price;
        setPriceDetail([...priceDetail]);
    };

    useEffect( () => {
        calcDetail();
    }, [ opt, options ]);

    return (
        <div className="legal-service-container">
            <Toast 
                className='copy-toast'
                show={toastContent.show}
                // autohide?: boolean;
                // animation?: boolean;
                // delay?: number;
                // onClose?: (e?: React.MouseEvent | React.KeyboardEvent) => void;
                // show?: boolean;
                // transition?: TransitionComponent;
                // bg={''}
            >
                <Toast.Body className='copy-toast-body'>
                    <div className="message" dangerouslySetInnerHTML={{__html: toastContent.message}}>
                    </div>
                    <button className='btn-close' onClick={closeToast}>
                    </button>
                </Toast.Body>
            </Toast>
            <ServiceHeader name={ service.name } iconPath={ `${process.env.REACT_APP_API_LEGAL_SERVICE_STORAGE}${service.iconPath}` } description={ service.description } />
            <div className="legal-service-subtitle">
                Pilih Jumlah
            </div>
            <ServiceOption 
                service={ service } 
                option={ opt } 
                options={ options } 
                onChoose={ (option, value = 0) => chooseQty(option, value) } />
            {
                opt !== null ? (
                    <CheckoutDetail 
                        order={orderDetail} 
                        price={priceDetail} 
                        inputs={inputs}
                        onModify={ (id, ref) => onModify(id, ref) } />
                ) : ''
            }
            <ServiceFooter 
                label="Lanjutkan Pembayaran" 
                description={ `
                    <span class='text-danger'>**</span>Dengan melanjutkan pembayaran, sobat sudah menyetujui <a target="_blank" href="${ process.env.REACT_APP_COMPANY_PROFILE_WEB_URL }terms-and-condition">syarat dan ketentuan</a> dan <a target="_blank" href="${ process.env.REACT_APP_COMPANY_PROFILE_WEB_URL }privacy-policy">kebijakan privasi Landack.</a>
                ` } 
                onClick={ () => checkout() }
                disabled={ buttonDisabled } 
            />
        </div>
    );
}

export default connect(mapStateToProps)(LegalServiceDetail);