import React, { useEffect, useState, useRef } from 'react';
import './BusinessEntityDetail.scss';

import Init from '../../../Init/Init';

import ServiceHeader from '../../Utilities/ServiceHeader/ServiceHeader';
import { objectIsEmpty } from '../../../Util/Util';
import LoadingPage from '../../Utilities/LoadingPage/LoadingPage';
import CheckoutDetail from '../CheckoutDetail/CheckoutDetail';
import ServiceFooter from '../../Utilities/ServiceFooter/ServiceFooter';
import { order } from '../../../Data/Transaction';
import { Toast } from 'react-bootstrap';

import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    return {
        customer: state.auth.data,
        isLogin: state.auth.isLogin
    };
}

const { constants, config } = Init;
const { whatsappIcon, puzzleIcon, activityIcon } = config;

function BusinessEntityDetail(props) {
    const [service, setService] = useState({});

    /* Login Check */
    const [ isLogin, setIsLogin ] = useState(false);
    const [ customer, setCustomer ] = useState({});

    useEffect( () => {
        setIsLogin(props.isLogin);
    }, [ props.isLogin ]);

    useEffect( () => {
        setCustomer( props.customer );
    }, [ isLogin, props.customer ]);

    useEffect( () => {
        setInputs({
            whatsappNo: {
                label: 'No. Whatsapp',
                icon: whatsappIcon,
                ref: whatsappNo,
                value: customer.customer_phone_number,
                placeholder: 'Tuliskan No. WhatsApp mu',
                type: 'phone'
            },
            stampAndCard: {
                label: 'Kartu Nama & Stampel',
                icon: activityIcon,
                ref: {
                    stample: {
                        label: 'Gunakan Stampel',
                        ref: stample,
                        value: 0,
                        type: 'yesno',
                        options: constants.flags.yesNo
                    },
                    cardName: {
                        label: 'Jumlah Kartu Nama',
                        ref: cardName,
                        value: '0',
                        type: 'number',
                        placeholder: 'x100'
                    }
                },
                type: 'group',
                affectPrice: true
            }
        });
    }, [ customer ]);

    const [ orderDetail, setOrderDetail ] = useState([]);
    const [ priceDetail, setPriceDetail ] = useState([]);
    const [ buttonDisabled, setButtonDisabled ] = useState(true); 
    const [toastContent, setToastContent] = useState({
        message: '',
        show: false
    });

    const whatsappNo = useRef('');
    const topic = useRef('');
    const stample = useRef('');
    const cardName = useRef('');

    const [inputs, setInputs] = useState({
        whatsappNo: {
            label: 'No. Whatsapp',
            icon: whatsappIcon,
            ref: whatsappNo,
            value: '',
            placeholder: 'Tuliskan No. WhatsApp mu',
            type: 'phone'
        },
        stampAndCard: {
            label: 'Kartu Nama & Stampel',
            icon: activityIcon,
            ref: {
                stample: {
                    label: 'Gunakan Stampel',
                    ref: stample,
                    value: 0,
                    type: 'yesno',
                    options: constants.flags.yesNo
                },
                cardName: {
                    label: 'Jumlah Kartu Nama',
                    ref: cardName,
                    value: '0',
                    type: 'number',
                    placeholder: 'x100'
                }
            },
            type: 'group',
            affectPrice: true
        }
    });

    const checkout = () => {
        const clientName = inputs.whatsappNo.value;
        const serviceCode = service.data.code;
        const serviceType = constants.serviceType.businessEntity;
        const quantity = 1;
        const transactionNotes = '';
        const useStamp = inputs.stampAndCard.ref.stample.value;
        const cardName = inputs.stampAndCard.ref.cardName.value;

        ( async () => {
            const response = await order( clientName, serviceCode, serviceType, quantity, transactionNotes, {
                use_stamp: useStamp,
                card_name: cardName
            } );
            
            if( response.hasOwnProperty('errorCode') && response.errorCode === 0 ) {
                window.open( response.data.paymentLink, '_blank' );
                setButtonDisabled(true);
            } else {
                console.log(response);

                setToastContent({
                    message: response.data.map((item) => {
                        return item.message;
                    }).join('<br />'),
                    show: true
                });
            }
        } )();
    };

    const onModify = (id, ref) => {
        const inputCopy = inputs;
        if( inputCopy[id].type === 'group' ) {
            for( let index in Object.keys(inputCopy[id].ref) ) {
                let key = Object.keys(inputCopy[id].ref)[index];
                let item = inputCopy[id].ref[key];

                if( item.type === 'yesno' ) {
                    item.value = item.ref.current;
                } else {
                    item.value = item.ref.current.value;
                }
            }
        } else {
            inputCopy[id].ref = ref;
            inputCopy[id].value = ref.current.value;
        }

        const whatsappValue = inputCopy.whatsappNo.value;
        const stampValue = inputCopy.stampAndCard.ref.stample.value;
        const cardNameValue = inputCopy.stampAndCard.ref.cardName.value;

        setInputs({
            whatsappNo: {...inputCopy.whatsappNo},
            stampAndCard: {...inputCopy.stampAndCard}
        });

        setButtonDisabled( whatsappValue === '' );
    };

    const closeToast = () => {
        setToastContent({
            ...toastContent,
            show: false
        });
    };

    useEffect( () => {
        setService(props.service);
    }, [props.service]);

    useEffect( () => {
        if(!objectIsEmpty(service) && !objectIsEmpty(service.data)) {
            let detailOrder = [
                {label: 'Jenis Layanan', value: service.data.name},
                {label: 'Total', value: `1 Badan Usaha`}
            ];
            let detailPrice = [
                {label: 'Total', value: service.data.price},
            ];

            if( inputs.stampAndCard.ref.stample.value === 1 ) {
                detailPrice.unshift({
                    label: inputs.stampAndCard.ref.stample.label,
                    value: service.addOns.stamplePrice
                });

                detailPrice[detailPrice.length - 1].value += parseInt(detailPrice[0].value);
            }

            if( inputs.stampAndCard.ref.cardName.value > 0 ) {
                inputs.stampAndCard.ref.cardName.value = Math.floor(Math.min(10, inputs.stampAndCard.ref.cardName.value));

                detailOrder = [
                    {label: 'Jenis Layanan', value: service.data.name},
                    {label: 'Jumlah Kartu Nama', value: inputs.stampAndCard.ref.cardName.value},
                    {label: 'Total', value: `1 Badan Usaha`}
                ];

                detailPrice.unshift({
                    label: inputs.stampAndCard.ref.cardName.label,
                    value: inputs.stampAndCard.ref.cardName.value * service.addOns.cardNamePrice
                });

                detailPrice[detailPrice.length - 1].value += parseInt(detailPrice[0].value);
            }

            setOrderDetail([
                ...detailOrder
            ]);
    
            setPriceDetail([
                ...detailPrice
            ]);
        }
    }, [service, inputs]);

    return !objectIsEmpty(service) && !objectIsEmpty(service.data) ? (
        <div className='business-entity-detail'>
            {/**
             * Toast
             * Header
             * Qty Options
             * Detail (CheckoutDetail)
             * 
             */}
            <Toast 
                className='copy-toast'
                show={toastContent.show}
                // autohide?: boolean;
                // animation?: boolean;
                // delay?: number;
                // onClose?: (e?: React.MouseEvent | React.KeyboardEvent) => void;
                // show?: boolean;
                // transition?: TransitionComponent;
                // bg={''}
            >
                <Toast.Body className='copy-toast-body'>
                    <div className="message" dangerouslySetInnerHTML={{__html: toastContent.message}}>
                    </div>
                    <button className='btn-close' onClick={closeToast}>
                    </button>
                </Toast.Body>
            </Toast>
            <ServiceHeader name={ service.data.name } iconPath={ `${process.env.REACT_APP_API_BUSINESS_ENTITY_STORAGE}${service.data.iconPath}` } description={ service.data.name } />
            <CheckoutDetail 
                order={orderDetail} 
                price={priceDetail}
                inputs={inputs} 
                onModify={ (id, ref) => onModify(id, ref) } />
            <ServiceFooter
                label="Lanjutkan Pembayaran" 
                description={ `
                    <span class='text-danger'>**</span>Dengan melanjutkan pembayaran, sobat sudah menyetujui syarat dan ketentuan dan kebijakan privasi Landack.
                ` } 
                onClick={ () => checkout() }
                disabled={ buttonDisabled }
            />
        </div>
    ) : '';
}

export default connect(mapStateToProps)(BusinessEntityDetail);